<template>
	<v-layout row justify-center align-center>
		<v-menu v-model="firstDateMenu" :close-on-content-click="false" :nudge-right="40" lazy transition="scale-transition" offset-y full-width min-width="290px">
			<template v-slot:activator="{ on }">
				<v-flex mr-2 shrink>
					<v-text-field
						:value="firstDate"
						:label="$t('actions.filter_date_by.after')"
						prepend-icon="event"
						readonly
						clearable
						size="10"
						v-on="on"
						@click:clear="resetFirstDate"
					/>
				</v-flex>
			</template>
			<v-date-picker
				v-model="afterDate"
				color="primary"
				:first-day-of-the-week="$firstDayOfTheWeek()"
				:locale="$i18n.locale"
				@input="firstDateMenu = false"
			></v-date-picker>
		</v-menu>
		<v-menu v-model="secondDateMenu" :close-on-content-click="false" :nudge-right="40" lazy transition="scale-transition" offset-y full-width min-width="290px">
			<template v-slot:activator="{ on }">
				<v-flex ml-2 shrink>
					<v-text-field
						:value="secondDate"
						:label="$t('actions.filter_date_by.before')"
						readonly
						clearable
						size="10"
						v-on="on"
						@click:clear="resetSecondDate"
					/>
				</v-flex>
			</template>
			<v-date-picker
				v-model="beforeDate"
				color="primary"
				:first-day-of-the-week="$firstDayOfTheWeek()"
				:locale="$i18n.locale"
				@input="secondDateMenu = false"
			></v-date-picker>
		</v-menu>
	</v-layout>
</template>

<script>
export default {
	name: 'DatesFilter',
	props: {
		value: {
			required: true,
			type: Object
		}
	},
	data: function () {
		return {
			secondDateMenu: false,
			firstDateMenu: false
		}
	},
	computed: {
		firstDate: function () {
			let result = ''
			if (this.value.afterDate) {
				if (this.value.afterDate instanceof Date) {
					result = this.$d(this.value.afterDate, 'tiny')
				} else {
					result = this.$d(new Date(this.value.afterDate), 'tiny')
				}
			}
			return result
		},
		secondDate: function () {
			let result = ''
			if (this.value.beforeDate) {
				if (this.value.beforeDate instanceof Date) {
					result = this.$d(this.value.beforeDate, 'tiny')
				} else {
					result = this.$d(new Date(this.value.beforeDate), 'tiny')
				}
			}
			return result
		},
		afterDate: {
			get: function () {
				return this.value.afterDate
			},
			set: function (newValue) {
				this.$emit('after-date', newValue)
			}
		},
		beforeDate: {
			get: function () {
				return this.value.beforeDate
			},
			set: function (newValue) {
				this.$emit('before-date', newValue)
			}
		}
	},
	methods: {
		resetFirstDate: function () {
			this.afterDate = null
		},
		resetSecondDate: function () {
			this.beforeDate = null
		}
	}
}
</script>
